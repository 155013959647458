@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
.hobbiesSection {
    background-color: rgb(243, 214, 73);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 82%;
    margin-top: 50px;
    margin-left: 9%;
}

.hobbiesCard {
    background-color: rgb(241, 238, 214);
    width: 90%;
    height: auto;
    display: flex;
    margin: 20px;
    border-radius: 20px;
}

.hobbiesIconIma {
    width: 20%;
    height: 240px;
    margin: 15px;
    border-radius: 15px;
}

.hobbiesCardParagrafSection {
    margin-top: 15px;
    width: 80%;
}

.hobbiesCardParagrafTitle {
    font-size: 55px;
    color:rgb(205, 121, 101);
}

.hobbiesCardParagrafSecParag {
    margin: 8px;
    margin-bottom: 20px;
    font-size: 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 300px;
}

.hobbiesSectionTitle {
    margin: 10px;
    margin-top: 35px;
    font-size: 65px;
    color: rgb(140, 67, 6);
}

@media   all and (max-width: 500px) {
    .hobbiesSection {
        flex-direction: column;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .hobbiesCard {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .hobbiesIconIma {
        width: 80%;
        height: 300px;
    }

    .hobbiesCardParagrafTitle {
        text-align: center;
    }

    .hobbiesCardParagrafSecParag {
        text-align: center;
    }
}