@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MonteCarlo&family=Montserrat:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&family=MonteCarlo&family=Montserrat:ital,wght@1,500&family=Roboto:ital,wght@1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@1,200&family=Lobster&family=Manrope:wght@200&family=MonteCarlo&family=Montserrat:ital,wght@1,500&family=Source+Sans+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@1,200&family=Manrope:wght@200&family=MonteCarlo&family=Montserrat:ital,wght@1,500&family=Roboto:ital,wght@1,100&display=swap');
 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main {
    width: 100%;
    margin: 15px 0;
}
.mainPicture {
    width: 35%;
    height: 650px;
    border-radius: 5px;
    border-radius: 10px;
    position: static;
}

.paragrafSection {
    height: 450px;
    width: 45%;
    padding: 40px;
    margin: 20px; 
    
}
.h4-welcome {
    font-size: 50px;
    color: rgb(113, 67, 6);
    text-align: start;
    font-family: "Montserrat", sans-serif;
}

.h1-welcome{
    font-size: 40px;
    color: rgb(113, 67, 6);
    display: block;
    font-family: "Montserrat", sans-serif;
}
span {
    color: rgb(232, 158, 63);
    font-weight: 400;
    font-size: 40px;
}
.h3-wel {
    font-size: 25px;
    color: rgb(112, 115, 123);
    font-family: "Montserrat", sans-serif;

}
.welcomePara {
    font-size: 25px;
    color: black;
    margin-top: 5px;
    font-family: "Barlow", sans-serif;
    font-weight: 200px;
}
.welcomeTemplate{
    width: 95%;
    margin: 0 auto;
    background-color: rgb(241, 228, 214);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
}

.contact {
    margin-top: 20px;
    padding: 15px 25px;
    border-radius: 10px;
    color: rgb(113, 67, 6);
    background-color: whitesmoke;
    font-size: 20px;
}

.contact:hover {
    color: white;
    background-color: rgb(112, 115, 123);
    transition: all 0.3s ease 0s;

}

@media  all and (max-width: 1063px) {
    .welcomeTemplate {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
    }
    .paragrafSection {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: auto;
    }
    .welcomePara {
        font-size: 35px;
        text-align: center;
        
    }
    .contact {
        padding: 25px 45px;
        font-size: 35px;
    }
    .mainPicture {
        width: auto;
    }

    .h1-welcome {
        font-size: 50px;
    }

    span {
        font-size: 50px;
    }

    .h3-wel {
        font-size: 30px;
        text-align: center;
    }
}

@media all and (max-width: 500px) {
    .welcomeTemplate {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .paragrafSection {
        margin: 0px;
        padding: 50px;
    }
    .welcomePara {
        width: 100%;
    }
    .mainPicture {
        width: 100%;
        height: auto;
    }
    .h1-welcome {
        font-size: 30px;
    }
    span {
        font-size: 30px;
    }
    .h3-wel {
        text-align: start;
        font-size: 25px;
    }
    .welcomePara {
        font-size: 30px;
        font-weight: 200;
        text-align: start;
    }

    .contact {
        margin-top: 35px;
        padding: 15px;
        font-size: 25px;
    }
}