@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.contactPage {
    background-image: url(images/pexels-tobias-bjørkli-2335126.jpg);
    width: 100%;
    height: 2000px;
    margin-top: 10px;
    border-radius: 20px;
    background-color: #C58940;
    display: flex;
    justify-content: center;
}

.contactCard {
    margin-top: 100px;
    width: 50%;
    height: 550px;
    background-color: #FAF8F1;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contactTitle {
    font-size: 60px;
    color: #E5BA73;
    margin-bottom: 20px;
    font-weight: 300;
}

.contactInfo {
    margin: 10px;
    font-size: 35px;
    font-size: "Roboto", sans-serif;
    font-weight: 300;
    color: black;
}

.icons {
    width:auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contactIcon {
    font-size: 50px;
    display: block;
    margin-right: 15px;
    color: #743A36;
}

.contactPara {
    font-size: 35px;
    font-size: "Roboto", sans-serif;
    font-weight: 300;
}

@media  all and (max-width: 980px) {
    .contactCard {
        width: 80%;
    }
}

@media   all and (max-width: 500px){
    .contactPage {
        align-items: flex-start;
    }
    .contactCard {
        height: 400px;
        margin-top: 100px;
        width: 90%;
    }

    .contactTitle {
        font-size: 32px;
    }

    .contactInfo {
        font-size: 28px;
    }

    .contactPara {
        font-size: 28px;
    }
}