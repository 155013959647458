@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MonteCarlo&family=Montserrat:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@1,200&family=Lobster&family=Manrope:wght@200&family=MonteCarlo&family=Montserrat:ital,wght@1,500&family=Source+Sans+Pro:wght@300&display=swap');

 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.logoHref {
    text-decoration: none;
}
.link {
    font-size: 25px;
    color: rgb(241, 238, 214);
    padding: 20px;
    border-radius: 1px;
    text-decoration: none;
    border-right: 1px solid rgb(241, 238, 214);
}

.link:hover , .link:focus {
    background-color: rgb(243, 214, 73);
    color: rgb(112, 115, 123);
    transition: all 0.3s ease 0s;
}

.contanier {
    border-bottom: 2px solid rgb(112, 115, 123);
    background-color:rgb(205, 121, 101);
    
}
nav{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 125px;
    justify-content: space-between;
    text-decoration: none;
    margin-right: 8%;
    margin-left: 3%;
}

.toggleButton {
    position: absolute;
    right: 1.5rem;
    top: 0.75rem;
    display: none;
    padding: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.icon {
    color: white;
    font-size: 50px;
    display: block;
    margin: 15px 0px;
}
.logo {
    cursor: pointer;
    background-color: rgb(205, 121, 101);
    color: rgb(241, 238, 214);
    font-family: "Lobster", sans-serif;
    font-weight: 200px;
    font-size: 55px;
}
.hamburgerLinks {
    display: none;
}
 #toogleMenu {
    display: none;
}
nav ul li {
    list-style-type: none;
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    
}

.contactButton {
    color: rgb(112, 115, 123);
    background-color: white;
    padding: 12px;
    border-radius: 5px;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
}
.contactButton:hover {
    color: white;
    background-color: rgb(112, 115, 123);
    transition: all 0.3s ease 0s;
    transform: scale(1.2);
}

.toggleMenu{
    width: 100%;
    height: 100px;
    background-color: #743A36;
    border-bottom: 2px solid rgb(113, 67, 6);
}


@media all and (max-width: 1060px) {
    #toogleMenu {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 200px;
        margin-bottom: 15px;
        background-color:rgb(205, 121, 101);
        border-radius: 20px;
    }
    .navLinks {
        display: none;
    }
    .contactButton {
        display: none;
    }
    .toggleButton {
        display: inline-block;
    }
    .contanier {
        width: 100%;
    }

    .logo {
        font-size: 35px;
    }

    .hamburgerLinks{
        list-style-type: none;


    }
    .hamburgerLink {
        background-color: rgb(205, 121, 101);
        font-size: 35px;
        padding: 10px;
        padding-right: 75px;
        padding-left: 75px;
        margin: 5px;
        text-decoration: none;
        color:  rgb(241, 238, 214);
        font-family: "Lobster", sans-serif;
    }

}